import React from "react";
import Layout from "../components/layout"
import Stripe from "../components/stripe"
import Background from "../components/background"
import styled from "styled-components"
import style from "../styles/main"
import Media from 'react-media'
import PageMetadata from "../components/SEO"

const About = styled.div`
  font-family: rewirFont;
  font-size: 1.25em;
  text-align: left;
  text-align-last: left;
  position: relative;
  padding-bottom: 25px;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    width: calc(100% - 56px);
    margin: auto;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    left: 15%;
    width: 75%;
    padding-top: 10vh;
    margin-left: 0;
  }
  a {
    text-decoration: none;
    color: ${style.textColor};
  }
  a:hover {
    text-decoration: underline;
  }
`

const AboutImg = styled.img`
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    width: 100%;
    padding-top: 28px;
    margin: auto;
    box-sizing: border-box;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    width: auto;
    position: relative;
    margin-left: 50%;
    max-height: 60vh;
  }
`

const AboutText = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    flex-wrap: wrap;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    flex-wrap: nowrap;
    margin-top: 20px;
  }
`

const Column = styled.div`
  padding-top: 28px;
  flex-grow: 1;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    width: 100%;
    padding-bottom: 28px;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    margin-top: 0px;
    margin-right: 10%;
    width: 80%;
    flex-basis: 0;
  }
`;

const Title = styled.div`
  font-family: rewirFont;
  font-size: ${props => props.small ? "3em" : "1.25em"};
  color: ${style.menuColor};
  margin-bottom: 15px;
`;

const VerticalLine = styled.div`
  border-left: 1px solid black;
  padding-left: 5px;
`;

const Para = styled.div`
  padding-top: 15px;
`;


function paragraph(year, items) {
  const rows = items.map((item) => {return <div>{'\u2013'} {item}</div>})
  return (
    <Para>
      <VerticalLine>{year}</VerticalLine>
      {rows}
    </Para>
  )
}

export default () => {
  return (
    <>
      <PageMetadata />
      <Layout initialHeight={0}>
        <Media query={`(min-width: ${style.mediaMinWidth}px)`}>
          <Background>
            <Stripe  m={8} l={20} w={50} h={50}/>
          </Background>
        </Media>
        <About>
          <AboutImg src="/about.jpg"/>
          <AboutText>
          <Column>
            <Title>me.</Title>
            <Para>Designer and researcher, PhD student at the Academy of Fine Arts in Warsaw.</Para>
            <Para>My projects have been presented at exhibitions in Warsaw, Porto and Copenhagen.</Para>
            <Para>I graduated from Product Design at ESAD College of Art and I am also a graduate of the Academy of Fine Arts in Warsaw, where he obtained a master's degree and is currently undergoing a PhD. I currently live and work in Warsaw's Powiśle distict.</Para>
          </Column>
            <Column>
              <Title>exhibition, research project & press</Title>

              {paragraph(2021,
                ['„Must Have 2021” for product BLOOM from Łódź Design Festival (May)',
                 '„Warsaw studio in the style of Le Corbusier”, publication of interior design by Label Magazine (May)',
                 '„Warsaw studio inspired by the interior of Villa Le Lac by Le Corbusier”, publication of interior design by Elle Decoration (April)'])}
              {paragraph(2020,
                ['„Ecomake 2020”, product BLOOM finalist of the competition, work presented at the online exhibition (November)',
                 '„Simplicity and contrasts. Interior design of an apartment in a building from the 90s” publication of interior design by Architektura&Biznes magazine (November)',
                 '„A green apartment inspired by the 90s”, publication of interior design by Interinity Home (September)',
                 '„Purity of form and contrasts in Solec”, publication of interior design by Label Magazine (September)',
                 '„Nostalgic apartment design in Solec”, publication of interior design by DesignAlive magazine (August)',
                 '„ZŻ discoveries: Plants by chance”, product publication about BLOOM by ZwykłeŻycie magazine (April)',
                 '„Design fair WZORY for Spring! Here are the top 5”, product publication about BLOOM by WhiteMad magazine (March)'])}
              {paragraph(2019,
                ['„We are arranging a city comfortable for the elderly”, Vistula Boulevards, Warsaw (June)',
                 '„Small spaces: microbiuro in the Old Town in Warsaw” publication of interior design by F5 magazine (June)',
                 '„Micro office in Warsaw Mariensztat” interior design publication by WhiteMad magazine (June)'])}
              {paragraph(2018,
                ['„The humanistic dimension of utility”, ArchiDay exhibition organized by the Interior Design Department of the Academy of Fine Arts in Warsaw, Warsaw (October)',
                 '„short story about LOVE” individual porcelain exhibition, Warsaw (September)'])}
              {paragraph(2017,
                ['„M jak Mieszkanie”, interior of the apartment (May)',
                 '„Geometry” exhibition organized by MULTImedia Group, Krakow (December to January)',
                 'Open call - Geometry, publication of ceramics by the MULTImedia Group (November)',
                 '„Defilad Square: a step forward as part of the 9th edition of Warsaw Under Construction”, Warsaw (October)',
                 '„Equilibrium - a new view of Plac Konstytucji” an individual exhibition on the 65th anniversary of the creation of Plac Konstytucji in Warsaw, organized by „Warsztat Warszawski” (September)',
                 '„Mood for Wood” post-workshop publication (August)',
                 '„From Earth” outdoor exhibition of ceramic sculptures, garden at the Palace in Chrzęsne (July)'])}
              {paragraph(2016,
                ['„Different faces of public space” collective exhibition organized by the Faculty of Interior Architecture, Academy of Fine Arts in Warsaw (October)',
                 '„Pitch black” after exhibition, Copenhagen (September)',
                 '„Pitch black” collective exhibition organized by Snedkernes Efterarsudstilling, Dogg Design team, Copenhage (September)'])}
               {paragraph(2015,
                 ['First place for a toy design for Kinder Niespodzianka',
                  'Finalist in the International Competition Procter & Gamble and Heinekennes'])}
            </Column>
          </AboutText>

        </About>
      </Layout>
    </>
  )
}
